/**
 * @generated SignedSource<<0c148cc6eaa18c2e60dd5fb7b7406f50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContinueYourProductsDashboardBlockContentPaginationFragment$data = {
  readonly id: string;
  readonly products: {
    readonly __id: string;
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly slug: string;
        readonly todos: number;
        readonly " $fragmentSpreads": FragmentRefs<"ProductCardFragment" | "MembershipPlanDetailsCardFragment">;
      };
    }>;
  };
  readonly " $fragmentType": "ContinueYourProductsDashboardBlockContentPaginationFragment";
};
export type ContinueYourProductsDashboardBlockContentPaginationFragment$key = {
  readonly " $data"?: ContinueYourProductsDashboardBlockContentPaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContinueYourProductsDashboardBlockContentPaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "products"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ContinueYourProductsDashboardBlockPaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ContinueYourProductsDashboardBlockContentPaginationFragment",
  "selections": [
    (v1/*: any*/),
    {
      "alias": "products",
      "args": null,
      "concreteType": "ProductNodeConnection",
      "kind": "LinkedField",
      "name": "__ContinueYourProductsDashboardBlockContent__products_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "todos",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProductCardFragment"
                },
                {
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "hideDrafts",
                      "value": true
                    },
                    {
                      "kind": "Literal",
                      "name": "hideNonPublic",
                      "value": true
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "MembershipPlanDetailsCardFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ForYouDashboardBlock",
  "abstractKey": null
};
})();

(node as any).hash = "ad3ddf89b30e18d88e0aeaf4488011f1";

export default node;
