import Badge from "@/admin/experiences/badges/Badge"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { PathwaySequenceProductFragment$key } from "@/dashboard/blocks/kinds/pathway-sequence/__generated__/PathwaySequenceProductFragment.graphql"
import ExperienceAdminDropdown from "@/experience/admin/dropdown/ExperienceAdminDropdown"
import MemberCurriculumProgressBar from "@/product/reports/page/curriculum/MemberCurriculumProgressBar"
import ProductUtils from "@/product/util/productUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import UserAvatarStack from "@/user/common/avatar-stack/UserAvatarStack"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoChip,
  DiscoDivider,
  DiscoIcon,
  DiscoSection,
  DiscoText,
} from "@disco-ui"
import { Theme, useMediaQuery } from "@material-ui/core"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { graphql, useFragment } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"

type Props = TestIDProps & {
  productKey: PathwaySequenceProductFragment$key
  isUnlocked: boolean
  pathwayGroupId: GlobalID
}

export default function PathwaySequenceProduct(props: Props) {
  const { testid = "PathwaySequenceProduct", productKey, isUnlocked } = props
  const isLgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"))
  const isXsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))
  const productLabel = useLabel("experience")
  const pathwayLabel = useLabel("pathway")
  const memberLabel = useLabel("product_member")
  const history = useHistory()
  const pathwayPermissions = useActiveProduct()!.viewerPermissions
  const timeZone = useUserTimezone()
  const pathwayProductDrawer = useGlobalDrawer("pathwayGroupProductRegistration")

  const product = useFragment<PathwaySequenceProductFragment$key>(
    graphql`
      fragment PathwaySequenceProductFragment on Product
      @argumentDefinitions(pathwayProductId: { type: "ID!" }) {
        id
        type
        name
        slug
        startsAt
        viewerCompletedAt
        isMembersAppVisibleToAll
        badge {
          ...BadgeFragment
        }
        viewerMembership {
          id
          creationDatetime
          ...MemberCurriculumProgressBarFragment
        }

        ...ExperienceAdminDropdownFragment

        productMemberships(roles: [member], first: 3) {
          totalCount
          edges {
            node {
              id
              member {
                id
                first_name: firstName
                last_name: lastName
                avatar
              }
            }
          }
        }

        courseProgressReport(pathwayProductId: $pathwayProductId) {
          notStarted
          inProgress
          completed
        }
      }
    `,
    productKey
  )
  const canManage = pathwayPermissions.has("pathways.manage")
  const classes = useStyles({ isLocked: !isUnlocked && !canManage })
  const totalMembers = product.productMemberships.totalCount

  // Only show the registered member UI if the group has been unlocked, not if they
  // registered elsewhere before they unlocked the group
  const isMember = isUnlocked && !!product.viewerMembership && !canManage
  const isInProgress = isMember && !product.viewerCompletedAt
  const viewerCTA = renderViewerCTA()

  return (
    <DiscoSection
      border
      groovyDepths={"insideCard"}
      padding={isXsDown ? 1.5 : 2}
      className={classes.container}
    >
      <div className={classes.row}>
        <Badge badgeKey={product.badge!} size={48} />
        <div className={classes.details}>
          <DiscoText
            data-testid={`${testid}.name`}
            variant={"body-md-600"}
            truncateText={2}
          >
            {product.name}
          </DiscoText>
          <DiscoText variant={"body-sm"} color={"text.secondary"} marginTop={0.5}>
            {isMember
              ? `Started on: ${formatDateWithOptions({ timeZone })(
                  new Date(product.viewerMembership.creationDatetime)
                )}`
              : ProductUtils.getStartDateText(product.type, product.startsAt)}
          </DiscoText>
        </div>
        {!isXsDown && viewerCTA}
      </div>

      {/* Admin View - Member Progress Stats */}
      {!isXsDown && canManage && (
        <div className={classNames(classes.row, classes.spaceBetween)}>
          <DiscoText>
            {`${pathwayLabel.singular} ${memberLabel.plural} in ${productLabel.singular}: `}
            <DiscoText variant={"body-md-600"} component={"span"} color={"text.primary"}>
              {product.productMemberships.totalCount}
            </DiscoText>
            <DiscoText component={"span"}>{" Registered"}</DiscoText>
            <span>{" • "}</span>
            <DiscoText
              variant={"body-md-600"}
              component={"span"}
              color={"groovy.yellow.500"}
            >
              {product.courseProgressReport!.inProgress}
            </DiscoText>
            <DiscoText component={"span"}>{" In Progress"}</DiscoText>
            <span>{" • "}</span>
            <DiscoText
              variant={"body-md-600"}
              component={"span"}
              color={"groovy.green.500"}
            >
              {product.courseProgressReport!.completed}
            </DiscoText>
            <DiscoText component={"span"}>{" Completed"}</DiscoText>
          </DiscoText>
          {renderGoToProductButton()}
        </div>
      )}

      {/* Member View - Progress */}
      {isInProgress && <DiscoDivider marginTop={0} marginBottom={0} />}

      {/* Push status details down to a new row on mobile */}
      {isXsDown && viewerCTA && <div className={classes.row}>{viewerCTA}</div>}

      {isInProgress && (
        <div className={classes.row}>
          {!isXsDown && (
            // Need empty div when >xs and no avatars so button gets pushed to the right
            <div className={classes.members}>
              {product.isMembersAppVisibleToAll && totalMembers > 0 && (
                <>
                  <UserAvatarStack
                    countVariant={"never"}
                    totalUsers={totalMembers}
                    users={Relay.connectionToArray(product.productMemberships).map(
                      (pm) => pm.member
                    )}
                    onClick={() => {
                      history.push(
                        generatePath(ROUTE_NAMES.PRODUCT.MEMBERS.ROOT, {
                          productSlug: product.slug,
                        })
                      )
                    }}
                  />
                  {isLgUp && (
                    <DiscoText color={"text.secondary"}>{`${totalMembers} ${
                      totalMembers === 1
                        ? `${memberLabel.singular} has`
                        : `${memberLabel.plural} have`
                    } enrolled in this ${productLabel.singular}`}</DiscoText>
                  )}
                </>
              )}
            </div>
          )}
          {renderGoToProductButton()}
        </div>
      )}
    </DiscoSection>
  )

  function renderViewerCTA() {
    if (canManage)
      return isXsDown ? (
        renderGoToProductButton()
      ) : (
        <ExperienceAdminDropdown productKey={product} rotateIcon />
      )
    // Don't show member/completion status if not unlocked yet (but registered elsewhere)
    if (!isUnlocked) return null
    if (product.viewerCompletedAt)
      return (
        <>
          <DiscoChip
            testid={`${testid}.completed`}
            label={"Completed"}
            leftIcon={<DiscoIcon icon={"check"} height={16} width={16} />}
            color={"green"}
            marginRight={isLgUp ? 4 : 0}
            style={{ flexShrink: 0 }}
          />
          {isLgUp && (
            <DiscoText variant={"body-sm"} color={"text.secondary"}>
              {`Completed on: ${formatDateWithOptions({ timeZone })(
                new Date(product.viewerCompletedAt)
              )}`}
            </DiscoText>
          )}
          {isMember && (
            <DiscoButton
              leftIcon={"arrow-stem-right"}
              variant={"outlined"}
              color={"grey"}
              size={"small"}
              to={generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
                productSlug: product.slug,
              })}
            />
          )}
        </>
      )
    if (isMember) {
      const isWide = isLgUp || isXsDown
      return (
        <MemberCurriculumProgressBar
          memberKey={product.viewerMembership}
          width={isXsDown ? "100%" : isLgUp ? "250px" : "100px"}
          hideLabels={!isWide}
          labelVariant={isWide ? "modules" : "percentage"}
        />
      )
    }
    return (
      <DiscoButton
        testid={`${testid}.register`}
        variant={"outlined"}
        color={"grey"}
        className={classes.button}
        onClick={() =>
          pathwayProductDrawer.open({ drawerPGID: props.pathwayGroupId, pId: product.id })
        }
      >
        {"Register Now"}
      </DiscoButton>
    )
  }

  function renderGoToProductButton() {
    return (
      <DiscoButton
        testid={`${testid}.go-to-product`}
        variant={canManage ? "outlined" : "contained"}
        color={canManage ? "grey" : "primary"}
        to={generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, { productSlug: product.slug })}
        rightIcon={"arrow-stem-right"}
        className={classes.button}
      >
        {`Go to ${productLabel.singular}`}
      </DiscoButton>
    )
  }
}

type StyleProps = {
  isLocked: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ isLocked }: StyleProps) => ({
    opacity: isLocked ? 0.5 : 1,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  }),
  row: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  details: {
    flexGrow: 1,
    flexShrink: 1,
  },
  members: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    flexGrow: 1,
    flexShrink: 1,
  },
}))
