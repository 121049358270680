import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import DashboardBlockItemTemplate from "../DashboardBlockItemTemplate"
import ContinueYourProductsDashboardBlockContent from "./ContinueYourProductsDashboardBlockContent"
import { ContinueYourProductsDashboardBlockFragment$key } from "./__generated__/ContinueYourProductsDashboardBlockFragment.graphql"

interface Props {
  dashboardBlockKey: ContinueYourProductsDashboardBlockFragment$key
  index?: number
}

export default function ContinueYourProductsDashboardBlock({
  dashboardBlockKey,
  index,
}: Props) {
  const block = useFragment<ContinueYourProductsDashboardBlockFragment$key>(
    graphql`
      fragment ContinueYourProductsDashboardBlockFragment on ForYouDashboardBlock {
        id
        position
        ...DashboardBlockItemTemplateFragment
        ...DashboardBlockAdminDropdownFragment
      }
    `,
    dashboardBlockKey
  )

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <ContinueYourProductsDashboardBlockContent block={block} />
    </DashboardBlockItemTemplate>
  )
}
