/**
 * @generated SignedSource<<8423e54f6314fdea79d02c978feb2410>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecentlyViewedDashboardBlockFragment$data = {
  readonly id: string;
  readonly position: DashboardBlockPosition;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockItemTemplateFragment" | "DashboardBlockAdminDropdownFragment">;
  readonly " $fragmentType": "RecentlyViewedDashboardBlockFragment";
};
export type RecentlyViewedDashboardBlockFragment$key = {
  readonly " $data"?: RecentlyViewedDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecentlyViewedDashboardBlockFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    }
  ],
  "type": "ForYouDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "1a0f19023a5128896bf5bfcc8f91388d";

export default node;
