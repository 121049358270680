import { ScrollToPathwayDashboardBlockParams } from "@/content-usage/modules/components/ContentModuleCompletionModal"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import PathwaySequenceProduct from "@/dashboard/blocks/kinds/pathway-sequence/PathwaySequenceProduct"
import { PathwaySequenceDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/pathway-sequence/__generated__/PathwaySequenceDashboardBlockFragment.graphql"
import { PathwaySequenceDashboardBlockQuery } from "@/dashboard/blocks/kinds/pathway-sequence/__generated__/PathwaySequenceDashboardBlockQuery.graphql"
import Relay from "@/relay/relayUtils"
import genericCoverImage from "@assets/images/covers/generic-cover-image.png"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoChip,
  DiscoIcon,
  DiscoSection,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { range } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import pluralize from "pluralize"
import { Fragment, useEffect, useState } from "react"
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useSubscribeToInvalidationState,
} from "react-relay"

type Props = TestIDProps & {
  dashboardBlockKey: PathwaySequenceDashboardBlockFragment$key
  index?: number
}

function PathwaySequenceDashboardBlock(props: Props) {
  const { testid = "PathwaySequenceDashboardBlock", dashboardBlockKey, index } = props
  const classes = useStyles()
  const productLabel = useLabel("experience")
  const pathwayLabel = useLabel("pathway")
  const theme = useTheme()
  const permissions = useActiveProduct()!.viewerPermissions
  const profileDrawer = useGlobalDrawer("profileSettings")
  const { authUser } = useAuthUser({ required: true })
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const certificatesDrawer = useGlobalDrawer("certificates")
  const [params, setParams] = useQueryParamState<ScrollToPathwayDashboardBlockParams>()

  const block = useFragment<PathwaySequenceDashboardBlockFragment$key>(
    graphql`
      fragment PathwaySequenceDashboardBlockFragment on PathwaySequenceDashboardBlock {
        id
        productId
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  const [refreshKey, setRefreshKey] = useState(0)

  const { node } = useLazyLoadQuery<PathwaySequenceDashboardBlockQuery>(
    graphql`
      query PathwaySequenceDashboardBlockQuery($id: ID!) {
        node(id: $id) {
          __typename
          ... on Product {
            id
            viewerHasCompleted
            childPathwayGroups {
              edges {
                node {
                  id
                  title
                  viewerMembership {
                    completedAt
                  }
                  products {
                    edges {
                      node {
                        id
                        ...PathwaySequenceProductFragment
                          @arguments(pathwayProductId: $id)
                      }
                    }
                  }
                }
              }
            }
            hasCertificateTemplate(releaseOption: on_product_completion)
            viewerCertificates(first: 1) {
              edges {
                node {
                  mediaUrl
                }
              }
            }
          }
        }
      }
    `,
    { id: block.productId! },
    { fetchPolicy: "network-only", fetchKey: refreshKey }
  )

  useSubscribeToInvalidationState([block.productId!], () => {
    // Refresh the block when the product is updated
    // needed for certificate create/update/delete and other actions
    setRefreshKey((prev) => prev + 1)
  })

  // Scroll to the pathway group or certification/completion section
  const [scrollToEl, setScrollToEl] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!scrollToEl) return
    scrollToEl.scrollIntoView({ behavior: "smooth", block: "center" })
    setParams(
      {
        certBlock: undefined,
        groupID: undefined,
      },
      "replace"
    )
    setScrollToEl(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToEl])

  const pathwayProduct = Relay.narrowNodeType(node, "Product")
  if (!pathwayProduct) return null

  const pathwayGroups = Relay.connectionToArray(pathwayProduct.childPathwayGroups)
  const totalProducts = pathwayGroups.reduce(
    (c, group) => c + group.products.edges.length,
    0
  )

  const hasCert = pathwayProduct.hasCertificateTemplate
  const userCertificate = pathwayProduct.viewerCertificates.edges[0]?.node
  const showCreateCertButton = !hasCert && permissions.has("certificate_templates.manage")
  const canManage = permissions.has("pathways.manage")
  const sectionPadding = isXsDown ? SECTION_PADDING_XS : SECTION_PADDING

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <DiscoSection border padding={sectionPadding}>
        <DiscoText
          variant={"body-md-600"}
          marginBottom={sectionPadding}
        >{`${pathwayLabel.singular} Sequence`}</DiscoText>
        <div className={classes.grid}>
          <DiscoIcon
            icon={"iconsax.custom-rocket"}
            color={theme.palette.groovy.neutral[500]}
          />
          <DiscoText variant={"body-md-500"} color={"groovy.neutral.500"}>{`${
            pathwayGroups.length
          } ${pathwayLabel.singular} ${pluralize(
            "Group",
            pathwayGroups.length
          )} • ${totalProducts} ${
            totalProducts === 1 ? productLabel.singular : productLabel.plural
          }`}</DiscoText>

          {pathwayGroups.map((group, gIdx) => (
            <Fragment key={group.id}>
              <div className={classes.path}>
                {renderLine(gIdx)}
                {renderIcon(gIdx)}
                {renderLine(gIdx + 1)}
              </div>
              <DiscoSection
                ref={(r) => (group.id === params.groupID ? setScrollToEl(r) : null)}
                border
                padding={sectionPadding}
                className={classes.groupDetails}
              >
                <div className={classes.groupTitle}>
                  <DiscoText
                    variant={"body-md-600"}
                    testid={`${testid}.group.${gIdx}.title`}
                  >
                    {group.title}
                  </DiscoText>
                  {renderStatus(gIdx)}
                </div>
                {Relay.connectionToArray(group.products).map((product, pIdx) => (
                  <PathwaySequenceProduct
                    key={product.id}
                    testid={`${testid}.group.${gIdx}.product.${pIdx}`}
                    productKey={product}
                    pathwayGroupId={group.id}
                    isUnlocked={Boolean(group.viewerMembership)}
                  />
                ))}
              </DiscoSection>
            </Fragment>
          ))}

          <div className={classes.path}>
            {renderLine(pathwayGroups.length - 1, true)}
            <DiscoIcon
              icon={"achievement"}
              active={pathwayProduct.viewerHasCompleted}
              color={
                pathwayProduct.viewerHasCompleted
                  ? theme.palette.primary.main
                  : theme.palette.groovy.neutral[500]
              }
            />
          </div>
          {renderCertificateSection()}
        </div>
      </DiscoSection>
    </DashboardBlockItemTemplate>
  )

  function renderLine(groupIdx: number, isCertificate = false) {
    const classList = [classes.line]
    if (groupIdx === 0) classList.push(classes.firstLine)
    if (isCertificate && showCreateCertButton) classList.push(classes.certButtonLine)

    // Use the last group's completion status for the line after it
    const group = pathwayGroups[Math.min(groupIdx, pathwayGroups.length - 1)]
    if (group.viewerMembership || canManage) {
      classList.push(classes.primaryLine)
      if (group.viewerMembership?.completedAt) {
        classList.push(classes.solidLine)
      }
    }
    return <div className={classNames(classList)} />
  }

  function renderIcon(groupIdx: number) {
    if (canManage)
      return <div className={classNames(classes.currentIcon, classes.adminIcon)} />
    const group = pathwayGroups[groupIdx]
    if (!group.viewerMembership)
      return (
        <DiscoIcon icon={"iconsax.lock-1"} color={theme.palette.groovy.neutral[500]} />
      )
    if (!group.viewerMembership.completedAt)
      return <div className={classes.currentIcon} />
    return (
      <DiscoIcon icon={"iconsax.tick-circle"} active color={theme.palette.primary.main} />
    )
  }

  function renderStatus(groupIdx: number) {
    if (canManage) return null
    const group = pathwayGroups[groupIdx]
    if (!group.viewerMembership)
      return (
        <>
          <DiscoText variant={"body-md"} color={"groovy.onDark.300"}>
            {"•"}
          </DiscoText>
          <DiscoIcon
            testid={`${testid}.group.${groupIdx}.locked-icon`}
            icon={"iconsax.lock-1"}
            color={theme.palette.groovy.onDark[300]}
            noShrink
          />
          {!isXsDown && (
            <DiscoText variant={"body-md"} color={"groovy.onDark.300"}>
              {"Has Prerequisite"}
            </DiscoText>
          )}
        </>
      )
    if (group.viewerMembership.completedAt)
      return (
        <DiscoChip
          testid={`${testid}.group.${groupIdx}.completed`}
          marginLeft={0.5}
          label={"Completed"}
          leftIcon={<DiscoIcon icon={"check"} height={16} width={16} />}
          color={"green"}
        />
      )
    return null
  }

  function renderCertificateSection() {
    // Admin gets prompted to add a certificate to the pathway if a published one doesn't exist
    if (showCreateCertButton)
      return (
        <div>
          <DiscoButton
            testid={`${testid}.create-certificate`}
            variant={"outlined"}
            color={"grey"}
            onClick={() =>
              certificatesDrawer.open({ drawerCertificatesProductId: pathwayProduct!.id })
            }
            leftIcon={"add"}
          >
            {"Create Pathway Certificate"}
          </DiscoButton>
        </div>
      )

    // If there's a certificate, indicate members will receive one upon completion
    const isComplete = pathwayProduct!.viewerHasCompleted
    const userHasCert = Boolean(userCertificate)
    if ((!isComplete && hasCert) || userHasCert)
      return (
        <DiscoSection
          ref={(r) => (params.certBlock === "1" ? setScrollToEl(r) : null)}
          testid={`${testid}.certificate.${isComplete ? "complete" : "incomplete"}`}
          border
          padding={sectionPadding}
          className={classes.certificateContainer}
        >
          <div>
            <DiscoText variant={"body-md-600"} marginBottom={1}>
              {userHasCert
                ? `Congratulations on your ${pathwayLabel.singular} completion! 🏅`
                : "Earn your Certificate 🏅"}
            </DiscoText>
            <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={3}>
              {userHasCert
                ? "Go to your profile to view and download your certificate."
                : `Complete all ${pathwayLabel.singular} Groups to earn your certificate.`}
            </DiscoText>
            {isXsDown && (
              <DiscoImage
                src={userCertificate?.mediaUrl ?? genericCoverImage}
                alt={"Example Certificate Image"}
                width={"100%"}
                height={"auto"}
                className={classes.certificateCover}
              />
            )}
            {userHasCert && (
              <DiscoButton
                testid={`${testid}.view-certificate`}
                onClick={() =>
                  profileDrawer.open({
                    drawerProfileId: authUser.id,
                    profileSettingsTab: "profile",
                  })
                }
              >
                {"View Certificate"}
              </DiscoButton>
            )}
          </div>
          {!isXsDown && (
            <DiscoImage
              src={userCertificate?.mediaUrl ?? genericCoverImage}
              alt={"Example Certificate Image"}
              width={240}
              height={120}
              className={classes.certificateCover}
            />
          )}
        </DiscoSection>
      )

    // If no certificate, show a generic pathway completion step
    return (
      <DiscoSection
        ref={(r) => (params.certBlock === "1" ? setScrollToEl(r) : null)}
        testid={`${testid}.no-certificate.${isComplete ? "complete" : "incomplete"}`}
        border
        padding={sectionPadding}
      >
        <DiscoText variant={"body-md-600"} marginBottom={1}>
          {isComplete
            ? `Congratulations on your ${pathwayLabel.singular} completion! 🏅`
            : `Complete the ${pathwayLabel.singular} 🏅`}
        </DiscoText>
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {isComplete
            ? `You've completed all ${pathwayLabel.singular} Groups and finished the ${pathwayLabel.singular}.`
            : `Complete all ${pathwayLabel.singular} Groups to finish the ${pathwayLabel.singular}.`}
        </DiscoText>
      </DiscoSection>
    )
  }
}

// Use constants for various gaps and padding so that we can calculate the exact height of
// the first-child vertical line so the path icons will align with the section titles
const PATH_GAP = 0.5
const SECTION_GAP = 1.5
const BASE_LINE_HEIGHT = SECTION_GAP - PATH_GAP
const SECTION_PADDING = 2.5
const SECTION_PADDING_XS = 1.5

const useStyles = makeUseStyles((theme) => ({
  grid: {
    display: "grid",
    gap: theme.spacing(0, 1),
    gridTemplateColumns: "min-content 1fr",
    "& > :nth-child(even):not(:nth-child(2))": {
      marginTop: theme.spacing(SECTION_GAP),
    },
  },
  groupDetails: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  groupTitle: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  path: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(PATH_GAP),
  },
  line: {
    flexGrow: 1,
    borderLeft: `1.5px dashed ${theme.palette.groovy.neutral[300]}`,
    "&:first-child": {
      maxHeight: theme.spacing(BASE_LINE_HEIGHT + SECTION_PADDING),
      [theme.breakpoints.down("xs")]: {
        maxHeight: theme.spacing(BASE_LINE_HEIGHT + SECTION_PADDING_XS),
      },
    },
  },
  firstLine: {
    "&:first-child": {
      // Manually add extra spacing to the top of the first line since there's no gap
      // between it and the top rocket icon
      marginTop: theme.spacing(PATH_GAP),
      maxHeight: theme.spacing(BASE_LINE_HEIGHT + SECTION_PADDING - PATH_GAP),
      [theme.breakpoints.down("xs")]: {
        maxHeight: theme.spacing(BASE_LINE_HEIGHT + SECTION_PADDING_XS - PATH_GAP),
      },
    },
  },
  primaryLine: {
    borderColor: theme.palette.primary.main,
  },
  solidLine: {
    borderLeftStyle: "solid",
  },
  certButtonLine: {
    "&:first-child": {
      maxHeight: theme.spacing(BASE_LINE_HEIGHT + 1),
    },
  },
  currentIcon: {
    borderRadius: "50%",
    width: 24,
    height: 24,
    padding: 2,
    border: `2px solid ${theme.palette.groovy.neutral[300]}`,
    "&::before": {
      content: "''",
      display: "block",
      borderRadius: "50%",
      width: "100%",
      height: "100%",
      border: `2px solid ${theme.palette.groovy.neutral[500]}`,
    },
  },
  adminIcon: {
    border: "4px solid transparent",
    "&::before": {
      border: "none",
      backgroundColor: theme.palette.primary.main,
    },
  },
  certificateContainer: {
    display: "flex",
    gap: theme.spacing(1.5),
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  certificateCover: {
    borderRadius: theme.measure.borderRadius.medium,
    border: theme.palette.constants.borderSmall,
  },
}))

function PathwaySequenceDashboardBlockSkeleton() {
  const classes = useStyles()
  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const sectionPadding = isXsDown ? SECTION_PADDING_XS : SECTION_PADDING

  return (
    <DiscoSection border padding={sectionPadding}>
      <DiscoTextSkeleton width={150} marginBottom={sectionPadding} />
      <div className={classes.grid}>
        <Skeleton width={24} height={24} />
        <DiscoTextSkeleton width={250} />
        {range(2).map((i) => (
          <Fragment key={i}>
            <div className={classes.path}>
              <div className={classes.line} />
              <Skeleton width={24} height={24} />
              <div className={classes.line} />
            </div>
            <Skeleton
              variant={"rect"}
              width={"100%"}
              height={300}
              style={{
                borderRadius: theme.measure.borderRadius.big,
                marginTop: theme.spacing(sectionPadding),
              }}
            />
          </Fragment>
        ))}
      </div>
    </DiscoSection>
  )
}

export default Relay.withSkeleton({
  component: PathwaySequenceDashboardBlock,
  skeleton: PathwaySequenceDashboardBlockSkeleton,
})
